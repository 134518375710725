<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Przypominanie hasła
          </p>
          <p class="mb-2">
            Podaj adres e-mail powiązany z Twoim kontem.
            Prześlemy na niego dalsze instrukcje.
          </p>
        </v-card-text>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="E-mail"
              placeholder="jan.kowalski@przyklad.pl"
              hide-details
              class="mb-3"
            ></v-text-field>
            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="sendRecoveryMail"
            >
              Resetuj Hasło
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <router-link :to="{ name:'pages-login' }">
            Wróć do logowania
          </router-link>
        </v-card-text>
      </v-card>
    </div>
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiEyeOutline, mdiEyeOffOutline} from '@mdi/js'
import {ref} from '@vue/composition-api'
import UserService from '@/services/UserService'

export default {
  setup() {
    const email = ref('')

    return {
      email,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    }
  },
  methods: {
    setSnackBar(snackbarColor, snackbarContent) {
      this.snackbar = true
      this.snackbarColor = snackbarColor
      this.snackbarContent = snackbarContent
    },
    sendRecoveryMail() {
      UserService.recoverPassword({email: this.email}).then(response => console.log(response))
      console.log('Hasło zresetowne')
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
